import React from "react";
import { Shimmer } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./ExlyImage.styles";
import classnames from "classnames";

/**
 * @deprecated - use ExlyImage component from "src/features/Common/modules/ExlyImage/ExlyImage.jsx" instead
 */
export default function ExlyImage({
  src,
  alt,
  className,
  height,
  width,
  loading,
  id,
  ...rest
}) {
  const showLoader = height && width; // loader will only work is height and width is provided
  const [didLoad, setLoad] = React.useState(showLoader);

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <>
      {/* eslint-disable-next-line no-restricted-syntax */}
      <img
        loading={"lazy" || loading}
        src={src}
        onLoad={() => {
          if (!showLoader) return;
          setLoad(true);
        }}
        alt={alt}
        className={classnames(
          className,
          showLoader && !didLoad && classes.notLoaded
        )}
        height={height}
        width={width}
        id={id}
        {...rest}
      />
      {showLoader && !didLoad ? (
        <Shimmer
          height={height}
          width={width}
          borderRadius="8px"
          wrapper_classname={classes.shimmerDiv}
        />
      ) : null}
    </>
  );
}
